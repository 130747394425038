/**
 * This module provides functions to retrieve navigation filters for the FilterBar component.
 *
 * This is not defined as Vuex getters because some functions within the filters themselves require calling some
 * store actions; while no actions would be called within the getter itself, this is not allowed by Vuex as we can only
 * access the store's state or getters within a getter.
 * This is not defined as Vuex actions either because this would make its integration more complex in components,
 * as it would turn the filter returning functions into a Promise.
 * Additionally, storing ES6 class instances in a Vuex store would cause them to return to regular objects, losing all
 * of their methods and wreaking havoc upon the filter bar.
 */
import * as api from '@/api'
import { useMLResultsStore, useAllowedMetaDataStore, useClassificationStore, useCorporaStore } from '@/stores'
import { UUID_REGEX } from '@/config'

/**
 * A filter bar filter.
 * @template T
 * @property {string} name Name of the filter. Used as a key in the component's v-model.
 * @property {string} displayName Displayed filter name. Defaults to the name, capitalized.
 * @property {string} placeholder Text shown as a placeholder when the user is typing a value for the filter.
 * @property {string?} operatorName Optional key to include the filter's configured operator in the component's v-model.
 * @property {string} operatorPlaceholder Text shown as a placeholder when the user is selecting an operator for the filter.
 * @property {{ [key: string]: string }} operators Available filter operators, as a mapping of operator keys and displayed names.
 */
export class Filter {
  /**
   * Create a new filter.
   *
   * @param {string} name Name of the filter.
   * @param {{
   *   displayName?: string,
   *   placeholder?: string,
   *   operatorName?: string,
   *   operatorPlaceholder?: string,
   *   operators?: { [key: string]: string }
   * }} options Optional properties of the filter.
   */
  constructor (name, options = {}) {
    this.name = name
    this.displayName = options.displayName ?? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase().replace(/_/g, ' ')
    this.placeholder = options.placeholder ?? 'Filter value'
    this.operatorName = options.operatorName
    this.operatorPlaceholder = options.operatorPlaceholder ?? 'Select an operator'
    this.operators = options.operators ?? { eq: '=' }
  }

  /**
   * Suggest possible filter values.
   *
   * @param {string} input A text input the user has typed. Can be empty.
   * The FilterInput component will further filter down the suggestions to ensure they match the input string;
   * this methods does not have to suggest only the values matching this input.
   * @param {string} operator The selected filter operator's key.
   * @returns {T[] | Promise<T[]>} A list of possible filter values. Can be a Promise,
   * in which case the component will show a loading state before displaying the results.
   */
  autocomplete () {
    return []
  }

  /**
   * Sort an array of suggested filter values for display.
   * Sorts alphabetically in ascending order by default.
   * This should not mutate the provided array.
   *
   * @param {[T, string][]} suggestions Array of `[value, display name]` to sort.
   */
  sort (values) {
    return [...values].sort(([, a], [, b]) => a.localeCompare(b))
  }

  /**
   * Get a human-readable string for a filter value.
   * @param {T} value A filter value to display.
   * @returns {string} A human-readable string for the filter value.
   */
  display (value) {
    return value.toString()
  }

  /**
   * Get a filter value from an arbitrary user input.
   * Is only called when the user types a string input and is not selecting a suggested value.
   * If an error is thrown, the input is rejected and the error message is displayed.
   * @param {string} input User input to validate.
   * @returns {T | Promise<T>} Filter value.
   * Can be a Promise, in which case the input shows a loading state while the validation runs.
   */
  validate (input) {
    return input
  }

  /**
   * Serialize an internal filter value into a string that can be used in the component's v-model.
   * @param {T} filterValue Filter value.
   * @return {string} Exposed v-model value.
   */
  serialize (filterValue) {
    return filterValue.toString()
  }

  /**
   * Deserialize a string from the component's v-model into an internal filter value.
   * @param {string} value Value from the component's v-model.
   * @param {string} operator The selected filter operator's key.
   * @returns {T} Deserialized filter value.
   */
  deserialize (value) {
    return value
  }
}

/**
 * A simple name filter, allowing any value.
 * @extends {Filter<string>}
 */
export class NameFilter extends Filter {
  constructor () {
    super('name', { operators: { eq: 'contains' } })
  }
}

/**
 * A rotation angle filter, allowing integers between 0 and 359
 * @extends {Filter<number>}
 */
export class RotationAngleFilter extends Filter {
  constructor () {
    super('rotation_angle', { placeholder: 'Angle between 0 and 359 degrees' })
  }

  validate (input) {
    /*
     * We could use parseInt, but parseInt('1.2') would return 1, a valid value.
     * No error message here would be confusing for the user.
     */
    if (typeof input !== 'number') input = Number.parseFloat(input)
    if (Number.isInteger(input) && input >= 0 && input <= 359) return input
    throw new Error('A rotation angle must be a number between 0 and 359 degrees')
  }
}

/**
 * An element type filter. It expects the corpus to already be loaded in the corpora module.
 * @typedef {{id: string, slug: string, display_name: string, folder: boolean}} ElementType
 * @extends {Filter<ElementType>}
 */
export class TypeFilter extends Filter {
  constructor (store) {
    super('type')
    this.store = store
    this.corporaStore = useCorporaStore()
  }

  /**
   * Retrieve the current corpus' types. A method is necessary because the types might change
   * during the filter's lifetime (e.g. when a type gets created or updated, or when the corpus gets loaded).
   * @returns {ElementType[]} A list of types.
   */
  autocomplete () {
    return Object.values(this.corporaStore.corpora[this.store.state.navigation.corpusId].types)
  }

  display (type) {
    return type.display_name
  }

  validate (input) {
    const found = this.autocomplete().find(type => type.display_name.toLowerCase() === input.toLowerCase())
    if (!found) throw new Error(`Type '${input}' not found.`)
    return found
  }

  serialize (type) {
    return type.slug
  }

  deserialize (slug) {
    return this.autocomplete().find(type => type.slug === slug)
  }
}

/**
 * An ML class filter, allowing the user to pick an ML class name and using IDs in the URL.
 * Uses the navigation's corpusId to provide autocompletion.
 * @typedef {{id: string, name: string}} MLClass
 * @extends {Filter<MLClass>}
 */
export class MLClassFilter extends Filter {
  constructor (store) {
    super('class_id', { displayName: 'Class' })
    this.store = store
  }

  async autocomplete (input = null) {
    const { results } = await useClassificationStore().listCorpusMLClasses(
      this.store.state.navigation.corpusId,
      { search: input }
    )
    return results
  }

  display ({ name }) {
    return name
  }

  async validate (input) {
    const validInputs = Object.fromEntries(
      (await this.autocomplete(input))
        .map(mlclass => [mlclass.name.toLowerCase(), mlclass])
    )
    const found = validInputs[input.toLowerCase()]
    if (found) return found
    throw new Error(`Unknown class '${input}'`)
  }

  async deserialize (input) {
    input = input.toString().toLowerCase()
    try {
      return await api.retrieveMLClass(
        this.store.state.navigation.corpusId,
        input
      )
    } catch {
      return undefined
    }
  }

  serialize ({ id }) {
    return id
  }
}

/**
 * A generic confidence filter, allowing floats between 0 and 1 with operators.
 * @extends {Filter<number>}
 */
export class ConfidenceFilter extends Filter {
  constructor (name, options = {}) {
    super(name, {
      placeholder: 'Confidence between 0.0 and 1.0',
      operators: {
        eq: '=',
        gt: '>',
        lt: '<',
        gte: '>=',
        lte: '<='
      },
      operatorName: `${name}_operator`,
      ...options
    })
  }

  validate (input) {
    if (typeof input !== 'number') input = Number.parseFloat(input)
    if (input >= 0 && input <= 1) return input
    throw new Error('A confidence must be a number between 0.0 and 1.0')
  }
}

/**
 * Create a worker version filter, allowing a worker version or 'No worker version' (false).
 * Uses the navigation's corpusId to provide autocompletion.
 * @typedef {import('@/types/worker').WorkerVersion} WorkerVersion
 * @extends {Filter<WorkerVersion | boolean>}
 */
export class WorkerVersionFilter extends Filter {
  constructor (currentCorpusId, name = 'worker_version', options = {}) {
    super(name, options)
    this.corporaStore = useCorporaStore()
    this.currentCorpusId = currentCorpusId
  }

  /**
   * Retrieve the corpus' worker versions, performing API requests as needed.
   * @returns {Promise<{[id: string]: WorkerVersion}>} The corpus' worker versions.
   */
  async fetch () {
    const corpus = this.corporaStore.corpora[this.currentCorpusId]

    const mlResultsStore = useMLResultsStore()
    if (mlResultsStore.corpusWorkerVersionsCache[corpus.id] === undefined) await mlResultsStore.listWorkerVersionsCache(corpus.id)
    return mlResultsStore.corpusWorkerVersions(corpus.id)
  }

  async autocomplete () {
    return [...Object.values(await this.fetch()), false]
  }

  display (workerVersion) {
    if (workerVersion === false) return 'No worker version'
    const suffix = workerVersion.revision ? workerVersion.revision.hash.substring(0, 8) : `version ${workerVersion.version}`
    return `${workerVersion.worker.name} (${suffix})`
  }

  async validate (input) {
    const result = await this.deserialize(input)
    if (result === undefined) throw new Error(`Unknown worker version ID: '${input}'`)
    return result
  }

  async deserialize (input) {
    if (['false', false].includes(input)) return false
    if (typeof input === 'object' && input.id) return input
    return (await this.fetch())[input]
  }

  serialize (version) {
    return version.id ?? false
  }
}

/**
 * Create a metadata name filter. Provides autocompletion via the navigation's corpusId from allowed metadata,
 * but allows any name to be typed, to handle metadatas created by workers or admins.
 * @extends {Filter<string>}
 */
export class MetadataNameFilter extends Filter {
  constructor (store) {
    super('metadata_name')
    this.store = store
  }

  /**
   * Offer the corpus' allowed metadata names as autocompletion, without requiring an exact name match
   * @returns {Promise<string[]>} Suggested metadata names.
   */
  async autocomplete () {
    const allowedMetadataStore = useAllowedMetaDataStore()
    const corpusId = this.store.state.navigation.corpusId
    // Load the corpus' allowed metadata if needed
    await allowedMetadataStore.listAllowedMetadata(corpusId)
    // Retrieve unique metadata names; there might be multiple AllowedMetadata with the same name and different types
    return [...new Set(allowedMetadataStore.allowedMetadata[corpusId].map(({ name }) => name))]
  }
}

/**
 * Create a metadata value filter. Note that the backend requires a metadata name to filter by metadata value.
 * Allows filtering with arithmetic operators, in which case the value must be a number.
 * @extends {Filter<string | number>}
 */
export class MetadataValueFilter extends Filter {
  constructor () {
    super('metadata_value', {
      operators: {
        eq: '=',
        gt: '>',
        lt: '<',
        gte: '>=',
        lte: '<=',
        contains: 'contains'
      },
      operatorName: 'metadata_operator'
    })
  }

  deserialize (input, operator) {
    // Math comparison operators expect floats
    if (['eq', 'contains'].includes(operator)) return input
    const number = parseFloat(input)
    if (Number.isFinite(number)) return number
  }

  validate (input, operator) {
    const result = this.deserialize(input, operator)
    if (result === undefined) throw new Error('Numeric comparison operators on metadata values require finite numbers')
    return result
  }
}

/**
 * String filter. No auto-completion.
 * @extends {Filter<string>}
 */
export class StringFilter extends Filter {
}

/**
 * Filter methods that are common to every strictly boolean filter
 * @extends {Filter<boolean>}
 */
export class BooleanFilter extends Filter {
  autocomplete () {
    return [true, false]
  }

  display (value) {
    if (typeof value === 'string') {
      /**
       * If the page is refreshed, or accessed from a link, then the filters come from the URL
       * and value is a string, not a boolean, so "value ?" doesn't work.
       */
      if (value === 'true') return 'Yes'
      return 'No'
    }
    return value ? 'Yes' : 'No'
  }

  sort (values) {
    // Preserve the sorting order returned from `autocomplete`
    return values
  }

  validate (input) {
    if (typeof input === 'boolean') return input
    input = input.toLowerCase()
    if (['true', 'false', 'yes', 'no'].includes(input)) return ['true', 'yes'].includes(input)
    throw new Error('Only Yes and No are allowed')
  }
}

/**
 * @extends {Filter<import('./types').UUID>}
 */
export class UUIDFilter extends Filter {
  constructor (name, options = {}) {
    super(name, {
      // Default, overridable placeholder
      placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
      ...options
    })
  }

  validate (input) {
    input = input.toLowerCase()
    if (UUID_REGEX.test(input)) return input
    throw new Error('Invalid UUID')
  }
}

/**
 * Retrieve the available filter bar filters depending on the navigation store's configuration.
 *
 * @param {Vuex.Store} store The frontend's store, as returned by `this.$store` in a component.
 * @param {{[key: string]: string}} context Currently set filters; the value returned by the filter bar's v-model.
 * @returns {Filter[]} The available filters.
 */
export const getNavigationFilters = (store, context = {}) => {
  const { corpusId, elementId } = store.state.navigation
  // A corpus ID is always required
  if (!corpusId) return []

  // Filters available in both corpora and folders
  const filters = [
    new NameFilter(),
    new RotationAngleFilter(),
    new BooleanFilter('mirrored'),
    new WorkerVersionFilter(store.state.navigation.corpusId),
    new MetadataNameFilter(store),
    new ConfidenceFilter('confidence'),
    new ConfidenceFilter('transcription_confidence'),
    new WorkerVersionFilter(store.state.navigation.corpusId, 'transcription_worker_version'),
    new StringFilter('creator_email'),
    new UUIDFilter('worker_run', { displayName: 'Worker run ID' }),
    new UUIDFilter('transcription_worker_run', { displayName: 'Transcription worker run ID' })
  ]

  // Only include the metadata value filter when the metadata name filter is set
  if (context.metadata_name) filters.push(new MetadataValueFilter())

  // The folder and type filters are mutually exclusive
  if (!context.folder) filters.push(new TypeFilter(store))
  if (!context.type) filters.push(new BooleanFilter('folder'))

  // Do not include the classification filters if we know for sure there are no classes on this corpus
  if (useClassificationStore().hasMLClasses[corpusId] !== false) {
    filters.push(
      new MLClassFilter(store),
      new ConfidenceFilter('classification_confidence'),
      new BooleanFilter('classification_high_confidence', {
        displayName: 'High confidence classification'
      })
    )
  }

  if (elementId) {
    // Filters only available when browsing a folder
    filters.push(new BooleanFilter('recursive'))
  } else {
    // Filters only available when browsing a corpus
    filters.push(new BooleanFilter('top_level'))
  }

  return filters
}
