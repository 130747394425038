<template>
  <div v-on:mouseleave="clean">
    <ImageLayer
      ref="svgImage"
      v-if="element.zone.image"
      v-model:scale="scale"
      v-model:position="viewPosition"
      :zone="element.zone"
      :rotation-angle="element.rotation_angle"
      :mirrored="element.mirrored"
      :class="{ 'dragCursor': isDragged }"
      v-on:wheel="e => mouseAction(e, 'zoom')"
      v-on:dblclick="e => mouseAction(e, 'dbClick')"
      v-on:mousedown="e => mouseAction(e, 'down')"
      v-on:mouseup="e => mouseAction(e, 'up')"
      v-on:mousemove="e => mouseAction(e, 'move')"
    >
      <template v-slot:overlay>
        <ZoomSlider v-model:scale="scale" />
      </template>
      <template v-slot:layer>
        <!-- Visible children -->
        <ElementZone
          v-for="hlElt in displayableElements"
          :key="hlElt.id"
          :element="hlElt"
          v-on:select="elementAction(hlElt)"
          :color="elementColor(hlElt)"
        />
        <svg
          v-bind="originalBoundingBox"
          ref="svgInput"
          :viewBox="elementViewBox"
        />
        <template v-if="enabled">
          <!--
            This transparent rectangle gets shown whenever we want all mouse events to be captured by
            the ImageLayer event handlers, bypassing all the others: this includes drawing a rectangle
            or polygon, or while dragging an existing polygon around.
          -->
          <rect
            v-if="captureCursor"
            v-bind="originalBoundingBox"
            fill="transparent"
          />
          <!-- Display the currently edited element using lines and circles -->
          <g v-if="selectedElement && selectedElement.zone !== null" v-on:mouseover="setHoveredId(selectedElement.id)" v-on:mouseleave="setHoveredId(null)">
            <!-- A polyline for the background color, allowing to drag the element around. -->
            <polyline
              :points="svgPolygon(selectedElement.zone.polygon)"
              :fill="DRAWN_POLYGON_COLOR"
              fill-opacity="0.5"
              class="mouseCursor"
            />
            <!-- Draw lines separately from the polyline; this might later allow dragging lines or creating median points -->
            <g
              v-for="(point, index) in selectedElement.zone.polygon.slice(0,-1)"
              :key="'line-' + index"
            >
              <!-- Hidden line to make hovering a polygon line easier -->
              <line
                v-if="tool === 'median'"
                :x1="point[0]"
                :y1="point[1]"
                :x2="selectedElement.zone.polygon[index + 1][0]"
                :y2="selectedElement.zone.polygon[index + 1][1]"
                :stroke="DRAWN_POLYGON_COLOR"
                v-on:mouseover="medianPointHover(index)"
                v-on:mouseleave="hoveredLine = null"
                v-on:mouseup.left="addMedianPoint(index)"
                stroke-opacity="0"
                stroke-width="10"
                vector-effect="non-scaling-stroke"
                :class="tool === 'median' ? 'medianCursor' : 'mouseCursor'"
              />
              <line
                :x1="point[0]"
                :y1="point[1]"
                :x2="selectedElement.zone.polygon[index + 1][0]"
                :y2="selectedElement.zone.polygon[index + 1][1]"
                :stroke="DRAWN_POLYGON_COLOR"
                :stroke-width="strokeWidth(index)"
                vector-effect="non-scaling-stroke"
                v-on:mouseover="medianPointHover(index)"
                v-on:mouseleave="hoveredLine = null"
                v-on:mouseup.left="addMedianPoint(index)"
                :class="tool === 'median' ? 'medianCursor' : 'mouseCursor'"
              />
              <!-- Preview a point to be added with the median point tool -->
              <line
                class="previewPoint"
                v-if="tool === 'median' && hoveredLine !== null"
                :x1="newPoint[0]"
                :y1="newPoint[1]"
                :x2="newPoint[0]"
                :y2="newPoint[1]"
                stroke="orange"
                :stroke-width="SMALLER_POINT_DIAMETER"
                stroke-linecap="round"
                vector-effect="non-scaling-stroke"
              />
            </g>
            <!--
              The non-scaling-stroke effect causes the points to keep the same size, no matter the zoom level.
              This is why we use a line here and not a circle; only the stroke can keep its size, not a whole shape.
              A circle's radius would be relative to the element, and re-computing its radius each time
              to fit the whole screen is too complex.
              The line starts and ends at the same point, so all we see is one round stroke linecap.

              We only map the mouse up and down event on this point; the move or wheel events will be captured
              by the ImageLayer. We also do not draw the last point, since it is the same as the first.
            -->
            <line
              v-for="(point, index) in selectedElement.zone.polygon.slice(0, -1)"
              :key="'point-' + index"
              :x1="point[0]"
              :y1="point[1]"
              :x2="point[0]"
              :y2="point[1]"
              :stroke="DRAWN_POLYGON_COLOR"
              :stroke-width="pointDiameter"
              stroke-linecap="round"
              vector-effect="non-scaling-stroke"
              v-on:mousedown="pointMouseDown(index)"
              v-on:mouseup="event => pointMouseUp(event, index)"
              :class="{ 'mouseCursor': tool === 'select' || (tool === 'polygon' && index === 0) }"
            />
          </g>
        </template>
      </template>
    </ImageLayer>
    <CreationForm
      v-if="createModal"
      :element="element"
      v-model:modal="createModal"
    />
    <DeleteModal
      v-if="selectedElement && tool === 'deletion'"
      :element="selectedElement"
    />
    <EditModal
      v-if="editModal"
      :element="selectedElement"
      v-model:modal="editModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import Mousetrap from 'mousetrap'
import {
  mapState as mapVuexState,
  mapMutations as mapVuexMutations,
  mapGetters as mapVuexGetters
} from 'vuex'
import { mapState } from 'pinia'
import { corporaMixin } from '@/mixins'
import { useCorporaStore } from '@/stores'

import {
  DRAWN_POLYGON_COLOR,
  DEFAULT_POLYGON_COLOR,
  ZOOM_FACTORS
} from '@/config'

import {
  boundingBox,
  checkPolygon,
  shiftPolygon,
  pointsEqual,
  polygonsEqual,
  errorParser,
  InvalidPolygonError,
  rotateAround,
  mirrorX,
  svgPolygon,
  medianPoint
} from '@/helpers'

import CreationForm from '@/components/Element/CreationForm.vue'
import EditModal from '@/components/Element/EditionForm.vue'
import ImageLayer from './ImageLayer'
import ZoomSlider from './ZoomSlider'
import ElementZone from './ElementZone'
import DeleteModal from './DeletionModal'

export default {
  /**
   * This component allows to visualize and edit
   * sub-elements on a given element image
   */
  components: {
    CreationForm,
    ImageLayer,
    ElementZone,
    ZoomSlider,
    DeleteModal,
    EditModal
  },
  mixins: [
    corporaMixin
  ],
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    POINT_DIAMETER: '15px',
    // For when points are displayed but can't be edited / dragged
    SMALLER_POINT_DIAMETER: '8px',
    DRAWN_POLYGON_COLOR,
    DEFAULT_POLYGON_COLOR,
    createModal: false,
    editModal: false,
    // Stores an edited polygon point index
    movedPointIndex: null,
    // Mouse is kept down
    mouseDown: false,
    // Required to compute a relative mouse translation
    lastMousePosition: null,
    // Set to true when the edited polygon has been modified (dragged or distorted)
    updatedPolygon: false,
    /**
     * When an annotation action is cancelled and the mouse was held down,
     * this is set to true to cause the next mouseup event to be ignored.
     * Note that this mouseup can occur anywhere, including outside of the component.
     */
    cancelled: false,
    /*
     * View scaling
     * Factor represents the zoom percentage index from ZOOM_FACTORS array
     */
    scale: { x: 0, y: 0, factor: 0, applied: true },
    // Image position
    viewPosition: { x: 0, y: 0, applied: false, recenter: true },
    // Hovered line in a selected polygon when using the median point tool
    hoveredLine: null
  }),
  beforeUnmount () {
    this.cleanVisible(this.element.id)
    if (this.selectedElement) this.selectElement(null)
  },
  computed: {
    ...mapVuexState('elements', ['elements']),
    ...mapVuexState('annotation', [
      'enabled',
      'tool',
      'batchCreation',
      'batchDeletion',
      'batchTypeEdition',
      'visible',
      'hoveredId',
      'selectedElement',
      'defaultType'
    ]),
    ...mapState(useCorporaStore, ['corpora']),
    ...mapVuexGetters('annotation', ['defaultName', 'batchCreateAvailable']),
    ...mapVuexGetters('elements', {
      // canWrite and canAdmin are already defined in corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin'
    }),
    ...mapVuexGetters('auth', ['isVerified']),
    /**
     * Bounding box of the polygon, ignoring rotation and mirroring.
     */
    originalBoundingBox () {
      return this.element.zone.polygon && boundingBox(this.element.zone)
    },
    /**
     * Center point of the element's bounding box, used to apply both mirroring and rotation.
     * Uses originalBoundingBox to allow it to be used in zoneBBox for the final bounding box computation
     */
    center () {
      return [
        Math.floor(this.originalBoundingBox.x + this.originalBoundingBox.width / 2),
        Math.floor(this.originalBoundingBox.y + this.originalBoundingBox.height / 2)
      ]
    },
    /**
     * The zone's bounding box after rotation. This potentially exceeds the image's bounds or has negative coordinates.
     */
    zoneBBox () {
      return boundingBox({
        polygon: this.element.zone.polygon.map(point => rotateAround(point, this.center, this.element.rotation_angle))
      }, { imageBounds: false })
    },
    elementViewBox () {
      const { x, y, width, height } = this.zoneBBox
      return [x, y, width, height].join(' ')
    },
    captureCursor () {
      // Use a SVG layer to capture mouse cursor position while drawing
      return (
        this.tool === 'polygon' ||
        // Moving a point or dragging the polygon in edition mode
        (this.tool === 'select' && this.selectedElement && this.mouseDown)
      )
    },
    hlIds () {
      // Returns visible IDs
      return this.visible[this.element.id] || []
    },
    displaySelectedElt () {
      // Return either the selected element is displayable or not
      const selectedElt = this.selectedElement
      return (selectedElt &&
        // Element is not being created
        selectedElt.id !== 'created-polygon' &&
        // Element has a zone
        selectedElt.zone &&
        // Element should not be already visible
        !this.hlIds.includes(selectedElt.id)
      )
    },
    displayableElements () {
      // Returns displayable sub-elements e.g. visible or selected
      const elements = this.hlIds.map(id => this.elements[id]).filter(e => e && e.zone)
      // Append a selected element in order to display it
      if (this.displaySelectedElt) elements.push(this.selectedElement)
      return elements
    },
    isDragged () {
      // Display a drag cursor when mouse is kept down except while drawing
      return (
        this.mouseDown && (
          !this.enabled ||
          this.tool === 'select'
        )
      )
    },
    corpusId () {
      // Required for corporaMixin
      return this.element.corpus.id
    },
    pointDiameter () {
      if (this.tool === 'median') return this.SMALLER_POINT_DIAMETER
      return this.POINT_DIAMETER
    },
    newPoint () {
      if (this.hoveredLine === null) return null
      const index = this.hoveredLine
      const point = this.selectedElement.zone.polygon.slice(0, -1)[index]
      return this.findMedianPoint(index, point)
    },
    batchMode () {
      return this.batchCreation || this.batchDeletion || this.batchTypeEdition
    }
  },
  methods: {
    ...mapVuexMutations('notifications', ['notify']),
    ...mapVuexMutations('annotation', [
      'setTool',
      'selectElement',
      'setHoveredId',
      'cleanVisible'
    ]),
    svgPolygon,
    clean () {
      if (!this.mouseDown) return
      // Clean editor when going outside component
      this.mouseDown = false
      this.lastMousePosition = null
      // Trigger a view update translating the image
      if (!this.viewPosition.recenter) this.viewPosition = { ...this.viewPosition, recenter: true }
      // Reset the edited polygon during an edition
      if (!this.selectedElement || !this.selectedElement.id) return
      const baseElt = this.elements[this.selectedElement.id]
      if (baseElt) this.select(baseElt)
    },

    elementLimits (position) {
      // Return a position within image limits
      const { x, y, width, height } = this.originalBoundingBox
      let [posX, posY] = position
      if (posX < x) posX = x
      else if (posX > x + width) posX = x + width
      if (posY < y) posY = y
      else if (posY > y + height) posY = y + height
      return [posX, posY]
    },

    getPosition (event) {
      // Returns relative mouse position on a scaled SVG layer
      const svgPoint = this.$refs.svgInput.createSVGPoint()
      Object.assign(svgPoint, { x: event.clientX, y: event.clientY })
      return svgPoint.matrixTransform(this.$refs.svgInput.getScreenCTM().inverse())
    },

    mouseAction (e, action) {
      // Ignore everything but the left mouse button
      if (action === 'down' && e.buttons !== 1) return

      // Nothing to do on a simple mouse move (no dragging)
      if (action === 'move' && (!this.mouseDown && !this.selectedElement)) return

      const position = this.getPosition(e)
      if (!position) throw new Error('Mouse position could not be determined on SVG layer.')
      const mouseCoords = [position.x, position.y]

      if (['up', 'down'].includes(action)) {
        this.mouseDown = action === 'down'

        /*
         * When an annotation is cancelled while the user holds the mouse button down,
         * cancelled is set to ignore the next mouseup event.
         * The mouseup event can however occur outside of this component, so it will never
         * be captured here; so if we catch a mousedown event instead, we will also
         * turn off the cancellation flag, without ignoring the event.
         */
        if (this.cancelled) {
          this.cancelled = false
          if (!this.mouseDown) return
        }

        /*
         * Remove focus from anything outside of the InteractiveImage:
         * we cannot directly focus on the SVG, but we need to unfocus any input, textarea, select
         * to make sure that keyboard shortcuts will not be ignored by Mousetrap.
         */
        document.activeElement.blur()
      }

      // Update the viewBox on mouseup event if the image has been translated
      if ((this.viewPosition.x || this.viewPosition.y) && !this.viewPosition.recenter && action === 'up') {
        this.viewPosition = { ...this.viewPosition, recenter: true }
        this.lastMousePosition = null
        return
      }

      // Dispatch actions
      if (action === 'zoom') {
        this.zoom(mouseCoords, e)
      } else if (!this.enabled || e.shiftKey) {
        if (this.mouseDown) this.translate(mouseCoords)
      } else if (this.tool === 'polygon') {
        this.editPolygon(mouseCoords, action)
      } else if (this.tool === 'rectangle') {
        this.editRectangle(mouseCoords, action)
      } else if (this.tool === 'select') {
        this.editSelected(mouseCoords, action)
      }

      // Set last move position
      if (this.mouseDown && action === 'move') {
        if (this.viewPosition.applied) {
          // Do not store mouse coords right after the CSS translation
          this.lastMousePosition = mouseCoords
        }
        this.viewPosition.applied = true
      } else {
        this.lastMousePosition = null
      }
    },

    translate (position) {
      if (!this.lastMousePosition) return
      const [dX, dY] = position.map((pt, index) => pt - this.lastMousePosition[index])
      this.viewPosition = {
        x: this.viewPosition.x + dX,
        y: this.viewPosition.y + dY,
        recenter: false,
        applied: false
      }
    },

    zoom (position, event) {
      if (this.mouseDown) return
      let factor = this.scale.factor + (event.deltaY < 0 ? 1 : -1)
      // Limit zoom factor
      if (factor < 0) factor = 0
      if (factor >= ZOOM_FACTORS.length) factor = ZOOM_FACTORS.length - 1
      // Factor has not been updated
      if (factor === this.scale.factor) return
      let [x, y] = this.elementLimits(position)
      if (this.element.mirrored) [x, y] = mirrorX([x, y], this.center)
      if (this.element.rotation_angle) [x, y] = rotateAround([x, y], this.center, this.element.rotation_angle)
      if (!this.scale.applied) this.scale = { ...this.scale, factor }
      else this.scale = { x, y, factor, applied: false }
    },

    async create () {
      if (!this.selectedElement) return
      // Check polygon and open element creation modal
      let polygon
      try {
        polygon = checkPolygon(this.selectedElement.zone.polygon)
        this.selectElement({
          ...this.selectedElement,
          zone: {
            ...this.selectedElement.zone,
            polygon
          }
        })
      } catch (e) {
        if (!(e instanceof InvalidPolygonError)) throw e
        this.notify({ type: 'error', text: e.message })
        this.selectElement(null)
        return
      }

      // Open the modal when batch annotation is not available on this corpus.
      if (!this.batchCreateAvailable(this.element.corpus.id)) {
        this.createModal = true
        return
      }

      try {
        await this.$store.dispatch('annotation/create', {
          corpus: this.element.corpus.id,
          parent: this.element.id,
          polygon
        })
        this.notify({ type: 'success', text: 'Element created.' })
      } catch (e) {
        this.notify({ type: 'error', text: `An error occurred during element creation: ${errorParser(e)}` })
      }
    },

    async editType (elt) {
      if (this.loading) return
      if (!this.canEdit(elt)) {
        this.notify({ type: 'error', text: "You don't have the necessary rights to edit this element's type." })
        return
      }

      if (this.batchTypeEdition && this.defaultType[this.corpusId]) {
        // Only trigger the typeEdit action when in batch edition mode and a type is selected
        this.loading = true
        try {
          await this.$store.dispatch('annotation/typeEdit', { element: elt })
        } catch (e) {
          this.notify({ type: 'error', text: `${errorParser(e)}` })
        } finally {
          this.loading = false
        }
      } else {
        this.selectElement(elt)
        this.editModal = true
      }
    },

    select (elt) {
      // Do not select an element after translating the image
      if (this.viewPosition.x || this.viewPosition.y) return
      // Allow to unselect an element
      if (!elt || (this.selectedElement && elt.id === this.selectedElement.id)) {
        this.selectElement(null)
        return
      }
      this.selectElement({
        id: elt.id,
        name: elt.name,
        type: elt.type,
        corpus: {
          id: elt.corpus.id
        },
        zone: {
          image: { ...elt.zone.image },
          polygon: [...elt.zone.polygon]
        }
      })
      if (this.$route.query.highlight !== elt.id) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            highlight: elt.id
          }
        })
      }
    },

    /**
     * Called when an ElementZone fires a select event.
     * This comes from a mouseup event, so this should be ignored after a cancellation.
     */
    elementSelect (elt) {
      if (this.cancelled) {
        this.cancelled = false
        return
      }
      this.select(elt)
    },

    elementAction (elt) {
      if (this.enabled && this.batchDeletion) this.elementDelete(elt)
      else if (this.enabled && this.tool === 'type-edit') this.editType(elt)
      else this.elementSelect(elt)
    },

    editPolygon (position, action) {
      const polygon = this.selectedElement && [...this.selectedElement.zone.polygon]
      const point = this.elementLimits(position)
      if (action === 'up') {
        // Create a temporary edited element with drawn polygon first and last point
        if (!polygon) {
          this.selectElement({
            id: 'created-polygon',
            zone: {
              image: { ...this.element.zone.image },
              polygon: [point, point]
            }
          })
          return
        }
        polygon.push(point)
      } else if (this.selectedElement && action === 'move') {
        // Add a segment between the last polygon point and the current cursor position
        polygon.splice(-1, 1, point)
      } else if (this.selectedElement && action === 'dbClick') {
        /*
         * Remove the above segment, which was only there only to provide feedback to the user,
         * and replace it with the first point of the polygon to close it.
         */
        polygon.splice(-1, 1, polygon[0])
      }

      // Update the edited element
      if (polygon) {
        this.selectElement({
          ...this.selectedElement,
          zone: {
            ...this.selectedElement.zone,
            polygon
          }
        })
      }

      // Trigger element creation on double click, only after updating the selected element
      if (this.selectedElement && action === 'dbClick') this.create()
    },

    editRectangle (position, action) {
      const polygon = this.selectedElement && cloneDeep(this.selectedElement.zone.polygon)
      if (!polygon && action === 'down') {
        // Create a temporary edited element with all rectangle points
        this.selectElement({
          id: 'created-polygon',
          zone: {
            image: this.element.zone.image,
            polygon: Array.from({ length: 5 }, () => [...position])
          }
        })
      } else if (this.selectedElement && action === 'move') {
        // Move rectangle points depending on mouse pointer position
        const limitedPosition = this.elementLimits(position)
        polygon.splice(2, 1, limitedPosition)
        // Second and third points respectively receive x and y cursor coordinates
        polygon[1][0] = limitedPosition[0]
        polygon[3][1] = limitedPosition[1]
      } else if (this.selectedElement && action === 'up') {
        return this.create()
      }
      if (polygon) {
        this.selectElement({
          ...this.selectedElement,
          zone: {
            ...this.selectedElement.zone,
            polygon
          }
        })
      }
    },

    /**
     * Display thicker lines when hovering using the median point tool.
     * @param {number} index Index of the hovered line in the polygon.
     */
    strokeWidth (index) {
      if (this.tool === 'median' && this.hoveredLine === index) return 5
      return 2
    },

    /**
     * When a polygon line is hovered, set hoveredLine to its index.
     */
    medianPointHover (index) {
      if (this.tool === 'median') {
        this.hoveredLine = index
      }
    },

    /**
     * Calculates a polygon line's median point.
     * Used to preview it as the line is hovered, then to insert it into the polygon.
     */
    findMedianPoint (index, point) {
      const polygon = this.selectedElement && [...this.selectedElement.zone.polygon]
      const newPoint = medianPoint(index, point, polygon)
      return newPoint
    },

    /**
     * Called when clicking on a polygon line using the median point tool.
     * Inserts that line's median point into the element polygon.
     */
    addMedianPoint (index) {
      if (this.tool !== 'median') return
      const polygon = this.selectedElement && [...this.selectedElement.zone.polygon]

      // Insert the new point
      polygon.splice(index + 1, 0, this.newPoint)

      // Update the edited element
      if (polygon) {
        this.selectElement({
          ...this.selectedElement,
          zone: {
            ...this.selectedElement.zone,
            polygon
          }
        })
      }
      this.updatedPolygon = true

      // Save new polygon
      this.saveSelected()
    },

    /**
     * Event handler for the mousedown event on a single point.
     *
     * With the selection tool, this sets movedPointIndex to begin moving a point.
     * With any other tool, we do nothing and let the ImageLayer's mouseup event deal with it.
     *
     * @param {number} index Zero-based index of the point in the polygon.
     */
    pointMouseDown (index) {
      if (this.tool === 'select') this.movedPointIndex = index
    },

    /**
     * Event handler for the mouseup event on a single point.
     *
     * With the polygon tool, a click on the first point should cause the polygon to be closed and created;
     * a click on any other point should do nothing.
     * With any other tool, we do nothing and let the ImageLayer's mouseup event deal with it.
     *
     * @param {MouseEvent} event Mouse event instance.
     * @param {number} index Zero-based index of the point in the polygon.
     */
    pointMouseUp (event, index) {
      // When an annotation action is cancelled, we should ignore the next mouseup event.
      if (this.cancelled) {
        this.cancelled = false
        return
      }
      if (this.tool !== 'polygon') return
      /*
       * Ensure clicking on any point with a polygon tool does not cause the ImageLayer's mouseup event to be fired
       * This prevents creating a new polygon right after finishing one.
       */
      event.stopPropagation()
      if (index !== 0) return
      /*
       * When the user moves the mouse around, editPolygon adds a fake point at the end of the polygon;
       * we therefore replace that final point with the first point to properly close the polygon.
       * Merely adding the first point with polygon.push(polygon[0]) would cause an extra point to be
       * present when the element is created.
       */
      const polygon = [...this.selectedElement.zone.polygon]
      polygon.splice(-1, 1, polygon[0])
      this.selectElement({
        ...this.selectedElement,
        zone: {
          ...this.selectedElement.zone,
          polygon
        }
      })

      // Trigger element creation
      this.create()
    },

    /**
     * Called by the generic editSelected event handler to handle a mouse move while a point is being dragged.
     * This is not an event handler by itself because this move event cannot be captured on the SVG element of a point,
     * since the mouse might end up moving outside of the point to drag it around.
     * Instead, the original event is captured by the ImageLayer event handlers due to the captureCursor rectangle.
     * @param {[number, number]} position X,Y coordinates of the mouse on the element.
     * @returns {[number, number][]} An updated polygon.
     */
    movePoint (position) {
      const polygon = [...this.selectedElement.zone.polygon]
      const limitedPosition = this.elementLimits(position)

      // Do nothing if the polygon was not updated
      if (pointsEqual(polygon[this.movedPointIndex], limitedPosition)) return

      polygon.splice(this.movedPointIndex, 1, limitedPosition)

      // The first and last points of a polygon are the same; when editing the first point, also edit the last one.
      if (this.movedPointIndex === 0) polygon.splice(-1, 1, limitedPosition)

      return polygon
    },

    /**
     * Called by the generic editSelected event handler to handle a mouse move while the SVG polyline is being dragged.
     * This is not an event handler by itself because this move event cannot be captured on the polyline,
     * since the mouse might end up moving outside of it to drag it around.
     * @param {[number, number]} position X,Y coordinates of the mouse on the element.
     * @returns {[number, number][]} An updated polygon.
     */
    movePolygon (position) {
      // Ignore any potential 0-pixel moves
      if (pointsEqual(this.lastMousePosition, position)) return

      const polygon = this.selectedElement.zone.polygon

      // Compute mouse translation vector relatively to image size
      const translationVector = position.map((pt, index) => pt - this.lastMousePosition[index])
      const newPolygon = shiftPolygon(polygon, translationVector)

      // If the translation results in any of the points being out of bounds, abort
      if (!newPolygon.every(point => pointsEqual(point, this.elementLimits(point)))) return
      return newPolygon
    },

    /**
     * Save the selected element once an update is completed.
     */
    async saveSelected () {
      // Reset edition data
      this.movedPointIndex = null
      if (this.lastMousePosition) {
        // Hover edited element again: the captureCursor layer caused the selected element to no longer be hovered.
        this.setHoveredId(this.selectedElement.id)
      }
      const baseElt = this.elements[this.selectedElement.id]
      // Do not save an unchanged polygon
      if (baseElt && !this.updatedPolygon) return

      this.updatedPolygon = false
      try {
        const reorderedPolygon = checkPolygon(this.selectedElement.zone.polygon)

        // The polygon was unchanged, no patch required!
        if (polygonsEqual(reorderedPolygon, baseElt.zone.polygon)) return

        await this.$store.dispatch('elements/patch', {
          id: this.selectedElement.id,
          polygon: reorderedPolygon
        })

        this.notify({ type: 'success', text: 'Element polygon updated.' })
      } catch (e) {
        // Cancel polygon update in case of error
        this.select(baseElt || null)
        this.notify({ type: 'error', text: `An error occurred while updating the element: ${errorParser(e)}` })
        throw e
      }
    },

    /**
     * Global mouse event handler for the selection tool.
     * @param {[number, number]} position X,Y coordinates of the mouse on the element.
     * @param {string} action Mouse event name.
     */
    editSelected (position, action) {
      if (action === 'down') {
        if (this.hoveredId && this.elements[this.hoveredId]) this.selectElement(this.elements[this.hoveredId])
        else this.selectElement(null)
        return
      }

      if (action === 'up') {
        if (this.selectedElement) this.saveSelected()
        else this.translate(position)
        return
      }

      if (action !== 'move' || !this.mouseDown) return

      // No selected element: the whole image should be dragged
      if (!this.selectedElement) {
        this.translate(position)
        return
      }

      // Defer to the point- or polygon-specific move methods to handle the actual moving
      let polygon = null
      if (this.movedPointIndex !== null) {
        polygon = this.movePoint(position)
      } else if (this.lastMousePosition) {
        polygon = this.movePolygon(position)
      }

      // An API request should be made to update the polygon once the move finishes.
      this.updatedPolygon = true

      // If we got a new polygon, update the element in the store
      if (polygon != null) {
        this.selectElement({
          ...this.selectedElement,
          zone: {
            ...this.selectedElement.zone,
            polygon
          }
        })
      }
    },

    toggleKeyboardShortcuts (value) {
      if (value) Mousetrap.bind('esc', this.abort)
      else Mousetrap.unbind('esc')
    },

    /**
     * Stop any ongoing edition action: drawing a new polygon, moving one, or moving a point.
     */
    abort () {
      if (this.tool === 'select' && this.mouseDown && (this.movedPointIndex !== null || this.lastMousePosition)) {
        /*
         * Moving a point or an existing polygon: reset the selected element to the backend data and reset the mouse data
         * If the element cannot be found for any reason, just deselect entirely.
         */
        this.selectElement(this.elements[this.selectedElement.id] ?? null)
        this.mouseDown = false
        this.movedPointIndex = null
        this.lastMousePosition = null
        this.updatedPolygon = false
        this.cancelled = true
      } else {
        // Drawing a new polygon: erase the selected element to delete it completely.
        this.selectElement(null)
      }
    },
    async canEdit (element) {
      return this.corpus && this.isVerified && this.canWrite(this.corpus) && (!element.rights || this.canWriteElement(element.id))
    },
    /**
     * Allow deleting elements only if the user is verified and has admin rights on the corpus.
     * Additionally, if the element has a `rights` attribute, look for an admin right.
     * This element rights check cannot be done for every element since the rights attribute is not available in all endpoints.
     */
    canDelete (element) {
      return this.corpus && this.isVerified && this.canAdmin(this.corpus) && (!element.rights || this.canAdminElement(element.id))
    },
    async elementDelete (elt) {
      if (this.loading) return
      if (!this.canDelete(elt)) {
        this.notify({ type: 'error', text: "You don't have the necessary rights to delete this element." })
        return
      }
      this.loading = true
      try {
        await this.$store.dispatch('elements/delete', { id: elt.id })
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.loading = false
      }
    },

    elementColor (element) {
      const elementColor = this.getType(element.type)?.color
      if (elementColor) return `#${elementColor}`
      else return DEFAULT_POLYGON_COLOR
    }
  },
  watch: {
    enabled: 'toggleKeyboardShortcuts',
    createModal (newValue) {
      /*
       * When the creation modal closes, erase the selected element
       * and reset the keyboard shortcuts, since the modal will remap the Esc key itself.
       */
      if (!newValue) {
        this.selectElement(null)
        // Use nextTick to wait until the modal closes and unbinds its key before binding ours
        this.$nextTick(() => this.toggleKeyboardShortcuts(true))
      }
    },
    element (newValue, oldValue) {
      if (newValue?.id === oldValue?.id) return
      // The element changed but the component was not re-mounted; reset the zoom and position
      this.scale = { x: 0, y: 0, factor: 0, applied: true }
      this.viewPosition = { x: 0, y: 0, applied: true, recenter: false }
    },
    batchMode (newValue, oldValue) {
      // When switching to a batch mode, unselect the currently selected element if any
      if (!oldValue && newValue && this.selectedElement) this.selectElement(null)
    }
  }
}
</script>

<style scoped lang="scss">
.flex-column {
  flex-direction: column;
}
.mouseCursor:hover {
  cursor: pointer;
}
.medianCursor:hover {
  cursor: crosshair;
}
.dragCursor {
  cursor: grabbing;
}
.previewPoint {
  pointer-events: none;
}
</style>
