
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'

import { WORKER_TYPE_COLORS, GIT_REF_COLORS, MODEL_VERSION_STATE_COLORS } from '@/config'
import { errorParser, ago } from '@/helpers'

import ItemId from '@/components/ItemId.vue'
import Modal from '@/components/Modal.vue'
import ProcessStateTag from '@/components/Process/StateTag.vue'
import { useWorkerStore, useNotificationStore } from '@/stores'
import { GitRefType, ModelVersionState } from '@/enums'

export default defineComponent({
  emits: {
    'update:modelValue' (value: boolean) {
      return typeof value === 'boolean'
    }
  },
  components: {
    ItemId,
    Modal,
    ProcessStateTag
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    workerRunId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    error: null as string | null
  }),
  mounted () {
    if (isEmpty(this.workerTypes)) this.listWorkerTypes()
  },
  computed: {
    ...mapState(useWorkerStore, ['workerRuns', 'workerTypes']),
    workerRun () {
      if (!this.workerRunId || !this.workerRuns) return
      return this.workerRuns[this.workerRunId]
    },
    workerType () {
      if (this.workerRun === undefined || isEmpty(this.workerTypes)) return
      return Object.values(this.workerTypes).find(t => t.slug === this.workerRun?.worker_version.worker.type)
    },
    modalState: {
      get () {
        return this.modelValue
      },
      set (value: boolean) {
        this.$emit('update:modelValue', value)
      }
    },
    versionCreatedDate () {
      if (this.workerRun) return ago(new Date(this.workerRun.worker_version.created))
      return ''
    }
  },
  methods: {
    ...mapActions(useWorkerStore, ['getWorkerRun', 'listWorkerTypes']),
    ...mapActions(useNotificationStore, ['notify']),
    humanBoolean (value: boolean): string {
      if (value) return 'Yes'
      return 'No'
    },
    async workerRunDetails () {
      if (!this.workerRunId || this.loading || this.workerRuns?.[this.workerRunId]) return
      this.loading = true
      this.error = null
      try {
        await this.getWorkerRun(this.workerRunId)
      } catch (err) {
        this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    },
    workerClass: (type: string) => {
      return WORKER_TYPE_COLORS[type]?.cssClass ?? WORKER_TYPE_COLORS.default.cssClass
    },
    refsClass: (type: GitRefType) => {
      return GIT_REF_COLORS[type]
    },
    modelVersionStateClass: (state: ModelVersionState) => {
      return MODEL_VERSION_STATE_COLORS[state]
    }
  },
  watch: {
    workerRunId: 'workerRunDetails',
    modalState: {
      immediate: true,
      handler (newValue) {
        if (newValue) this.workerRunDetails()
      }
    }
  }
})
