
import { mapActions, mapState } from 'pinia'
import { mapActions as mapVuexActions } from 'vuex'
import { PropType, defineComponent } from 'vue'

import { errorParser } from '@/helpers'
import { truncateMixin } from '@/mixins'
import { useNotificationStore, useWorkerStore } from '@/stores'

import EditableName from '@/components/EditableName.vue'
import Modal from '@/components/Modal.vue'
import CreateForm from './Create.vue'
import { UUID, WorkerRun } from '@/types'
import { WorkerVersion } from '@/types/worker'
import { WorkerConfiguration } from '@/types/workerConfiguration'
import { WorkerConfigurationCreatePayload } from '@/api'

export default defineComponent({
  components: {
    Modal,
    CreateForm,
    EditableName
  },
  mixins: [
    truncateMixin
  ],
  emits: {
    'selected-configuration': (value: WorkerConfiguration | undefined) => value === undefined || (typeof value === 'object' && value.id),
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    /*
     * This component supports two exclusives modes:
     * * Emitting a select-configuration event when the workerVersion property is set
     * * Managing worker runs property when workerRun property are set
     */
    modelValue: {
      type: Boolean,
      default: false
    },
    workerVersion: {
      type: Object as PropType<WorkerVersion | null>,
      default: null
    },
    workerRun: {
      type: Object as PropType<WorkerRun | null>,
      default: null
    },
    configurationId: {
      type: String as PropType<UUID>,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    archivedFilter: false,
    /**
     * Clicking the 'create configuration' button should always set configCreate to true
     * to show the creation form, and clicking on 'no configuration' or on some other
     * configuration in the list should close the form by setting configCreate to false.
     */
    configCreate: false,
    loading: false,
    configurationsError: null as string | null,
    // selectedConfigurationId stores the current selected configuration before it is confirmed by the user (Save)
    selectedConfigurationId: null as UUID | null,
    newConfiguration: {
      name: '',
      configuration: {}
    } as WorkerConfigurationCreatePayload
  }),
  mounted () {
    // Ensure that properties have been passed correctly
    if ((this.workerRun !== null) !== (this.workerVersion === null)) {
      throw new Error('Exactly one of workerRun or workerVersion property must be set')
    }

    this.selectedConfigurationId = this.configurationId || null
  },
  computed: {
    ...mapState(useWorkerStore, ['workerConfigurations']),
    modalTitle () {
      if (this.readOnly) return `Details for configuration ${this.truncateShort(this.selectedConfiguration?.name)}`
      if (this.workerVersion !== null) {
        return `Configure worker ${this.workerVersion.worker.name}`
      } else {
        return `Configure ${this.workerRun?.worker_version?.worker?.name}`
      }
    },
    processId () {
      return this.workerRun?.process?.id
    },
    workerId () {
      if (this.workerVersion !== null) {
        return this.workerVersion.worker.id
      } else if (this.workerRun !== null) {
        return this.workerRun.worker_version.worker.id
      }
      throw new Error('There is no workerRun or workerVersion')
    },
    workerVersionId () {
      if (this.workerVersion !== null) {
        return this.workerVersion.id
      } else if (this.workerRun !== null) {
        return this.workerRun.worker_version.id
      }
      throw new Error('There is no workerRun or workerVersion')
    },
    selectedConfiguration () {
      if (!this.selectedConfigurationId || !this.workerId) return
      return this.workerConfigurations[this.workerId]?.[this.selectedConfigurationId]
    },
    canSave () {
      return !this.loading &&
        // If a configuration ID is set, we must have this configuration in the store
        !this.selectedConfigurationId === !this.selectedConfiguration &&
        // Do not allow saving the currently selected worker configuration if said configuration is archived
        !this.selectedConfiguration?.archived &&
        // Do not allow saving while the configuration creation form is active
        !this.configCreate &&
        // Disable saving in read only mode
        !this.readOnly
    },
    canToggleArchive () {
      // This cannot check that the user has enough rights on the worker or the repository, because the API does not provide that anywhere.
      return !this.loading && !this.readOnly && !this.configCreate && this.selectedConfiguration
    },
    /**
     * Determines whether the archive button should be an Enable button (true) or an Archive button (false).
     * When a configuration is selected, its `archived` property is used; otherwise, we will display the
     * state that matches the current `archivedFilter`, but disabled, just for consistency.
     * @type {boolean}
     */
    archiveButtonState () {
      return this.selectedConfiguration?.archived ?? this.archivedFilter
    },
    archiveButtonTitle () {
      if (this.canToggleArchive) {
        if (this.archiveButtonState) return 'Enable this configuration'
        else return 'Archive this configuration'
      } else {
        if (this.archiveButtonState) return 'You must select an existing archived configuration in order to enable it.'
        else return 'You must select an existing enabled configuration in order to archive it.'
      }
    },
    saveButtonTitle () {
      if (this.canSave && this.selectedConfigurationId) return 'Select this configuration.'
      if (this.configCreate) return 'Create the new configuration first, or select an existing configuration to use.'
      if (this.loading) return 'Loading…'
      if (this.selectedConfiguration?.archived) return 'An archived configuration cannot be selected in a process.'
      return ''
    }
  },
  methods: {
    ...mapActions(useWorkerStore, [
      'listConfigurations',
      'updateConfiguration',
      'getConfiguration'
    ]),
    ...mapActions(useNotificationStore, ['notify']),
    ...mapVuexActions('process', ['updateWorkerRun']),

    createNewConfiguration () {
      // When clicking on "New configuration" the pre-filled value is reset
      this.newConfiguration = {
        name: '',
        configuration: {}
      }
      this.configCreate = true
    },

    async retrieveConfigurations () {
      if (!this.workerId || this.readOnly) return
      this.configurationsError = null
      this.loading = true
      try {
        await this.listConfigurations(
          this.workerId,
          { archived: this.archivedFilter }
        )
      } catch (err) {
        this.configurationsError = errorParser(err)
      } finally {
        this.loading = false
      }
    },

    async setConfiguration (configId: UUID | null) {
      if (this.readOnly || !this.workerId) return
      if (this.selectedConfigurationId !== configId) {
        if (configId !== null && !this.workerConfigurations[this.workerId][configId]) {
          this.loading = true
          try {
            await this.getConfiguration(this.workerId, configId)
            this.archivedFilter = this.workerConfigurations[this.workerId][configId].archived
          } catch (err) {
            this.notify({ type: 'error', text: errorParser(err) })
            return
          } finally {
            this.loading = false
          }
        }
        this.selectedConfigurationId = configId
      }
      if (this.configCreate) this.configCreate = false
    },

    async saveConfiguration () {
      /*
       * Saves the selected configuration to the process in worker run mode
       * Emits a selected-configuration in simple worker mode
       */
      if (!this.canSave || !this.workerId || !this.workerRun) return

      // In worker mode, simply emit the selected configuration and close the modal
      if (this.workerVersion !== null) {
        this.$emit('selected-configuration', this.selectedConfiguration)
        this.$emit('update:modelValue', false)
        return
      }

      // Do nothing if the configuration has been left unchanged
      if (this.selectedConfigurationId === this.configurationId) {
        if (this.configCreate) this.configCreate = false
        this.$emit('update:modelValue', false)
        return
      }

      this.loading = true
      const payload = { configuration_id: this.selectedConfigurationId }
      /**
       * Get the name of the configuration for the success notification now, in order to avoid a
       * potential race condition as updateWorkerRun causes workerId to be re-computed when a
       * configuration is first saved for a newly added worker run, which triggers
       * retrieveConfigurations again through a watcher.
       * When removing a configuration, selectedConfigurationId is null.
       */
      let configurationName = ''
      if (this.selectedConfigurationId) configurationName = this.workerConfigurations[this.workerId][this.selectedConfigurationId].name
      try {
        await this.updateWorkerRun({
          processId: this.processId,
          workerRunId: this.workerRun.id,
          payload
        })
        if (this.selectedConfigurationId) {
          this.notify({
            type: 'success',
            text: `Configuration ${configurationName} added to worker run.`
          })
        } else {
          this.notify({ type: 'success', text: 'Configuration removed from worker run.' })
        }
        this.$emit('update:modelValue', false)
        if (this.configCreate) this.configCreate = false
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.loading = false
      }
    },

    async toggleArchive () {
      if (!this.canToggleArchive || !this.selectedConfiguration || !this.workerId) return
      try {
        this.loading = true
        const newArchivedStatus = !this.selectedConfiguration?.archived
        await this.updateConfiguration(this.workerId, this.selectedConfiguration.id, {
          archived: newArchivedStatus
        })
        // Unselect the configuration if it has been archived
        if (newArchivedStatus) this.selectedConfigurationId = null
        // If the configuration has been enabled, switch off archivedFilter and keep it selected
        else this.archivedFilter = false
      } finally {
        this.loading = false
      }
    },

    prettify: function (value: unknown) {
      return JSON.stringify(value, null, 2)
    },

    cloneConfiguration () {
      if (this.readOnly || !this.selectedConfiguration) return
      this.newConfiguration = {
        configuration: this.selectedConfiguration.configuration,
        name: 'Copy of ' + this.selectedConfiguration.name
      }
      this.configCreate = true
    },

    /**
     * Used by the EditableName component
     */
    async renameConfiguration (name: string) {
      if (!this.workerId || !this.selectedConfigurationId) return
      await this.updateConfiguration(
        this.workerId,
        this.selectedConfigurationId,
        { name }
      )
      this.retrieveConfigurations()
    }

  },
  watch: {
    workerId: {
      immediate: true,
      handler () {
        this.retrieveConfigurations()
      }
    },
    configurationId (newValue) {
      this.selectedConfigurationId = newValue
    },
    archivedFilter (newValue, oldValue) {
      if (oldValue === newValue) return
      this.retrieveConfigurations()
    }
  }
})
