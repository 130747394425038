<template>
  <router-link :to="{ name: 'worker-version', params: { versionId: workerVersion.id } }" target="_blank">
    <span
      class="tag is-uppercase is-light mr-1"
      :title="workerVersion.worker.type"
      :class="workerClass"
    >
      {{ workerVersion.worker.type }}
    </span>
    {{ truncateLong(workerVersion.worker.name) }}
  </router-link>
  <br />
  <small>
    <template v-if="workerVersion.revision">
      <a :href="workerVersion.revision.commit_url" target="_blank">
        <samp>{{ workerVersion.revision.hash.substr(0, 8) }}</samp>
      </a>
      {{ truncateLong(workerVersion?.revision?.message || '—') }}
      &middot; {{ workerVersion?.revision?.author || '—' }}
    </template>
    <template v-else>Version {{ workerVersion.version }}</template>
  </small>
</template>

<script>
import { WORKER_TYPE_COLORS } from '@/config'
import { truncateMixin } from '@/mixins'

export default {
  props: {
    workerVersion: {
      type: Object,
      required: true
    }
  },
  mixins: [
    truncateMixin
  ],
  computed: {
    workerClass () {
      return WORKER_TYPE_COLORS[this.workerVersion.worker.type]?.cssClass ?? WORKER_TYPE_COLORS.default.cssClass
    }
  }
}
</script>
